<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card">
        <div class="card-header">
          <h4 class="text-center">
            <i class="fa fa-upload"></i>
            {{ e("import-from-excel") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="text-center g">
            <h4>{{ e("needed-forma") }}:</h4>
            (<a
              target="_blank"
              href="https://cdn-static.brmja.com/storage/uploads/files/62688a946128c632208381.xlsx"
              download
              >{{ e("click-here-to-download") }}</a
            >)
            <br />
            <img
              :src="require('@/assets/images/excel-needed.png')"
              style="width: 70%"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("settings-group") }}</h5>
            <select
              class="form-control form-control-lg"
              v-model="settings_group_id"
            >
              <template v-for="group in groups">
                <option :key="group._id" :value="group._id">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
          <!-- switch -->
          <div class="demo-inline-spacing">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="allow_location_app"
              :value="true"
              switch
              inline
            >
              <strong> {{ e("allow-location-app") }}</strong>
            </b-form-checkbox>
          </div>
          <div v-if="user.branches">
            <!-- switch -->
            <div class="demo-inline-spacing">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                v-model="all_branches"
                :value="true"
                switch
                inline
              >
                <strong> {{ e("allow-all-branches") }}</strong>
              </b-form-checkbox>
            </div>
            <div v-if="!all_branches" class="border g">
              <div
                class="demo-inline-spacing"
                v-for="branche in user.branches_list"
                :key="branche.id"
              >
                <b-form-checkbox
                  class="custom-control-primary"
                  name="check-button"
                  v-model="branches"
                  :value="branche.id"
                  switch
                  inline
                >
                  <strong> {{ branche.title }}</strong>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <!-- Styled -->
          <b-form-file
            class="form-control"
            @change="onFileChange"
            style="cursor: pointer !important"
            :placeholder="e('choose-or-drage-file') + '..'"
            accept=".xls,.xlsx"
            drop-placeholder="Drop file here..."
          />
        </div>
        <div
          class="card-footer text-center alert alert-success g"
          v-if="arr.length"
        >
          <h4>
            {{ e("done-import-collect-count") }}: {{ arr.length }}
            {{ e("user") }}
          </h4>
          <br />
          <button class="btn btn-relief-success btn-lg" @click="addNow()">
            استيراد الآن
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import readXlsxFile from "read-excel-file";

import {
  BLink,
  BOverlay,
  BFormCheckbox,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
    BFormFile,
    BCardText,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      arr: [],
      groups: [],
      branches: [],
      settings_group_id: null,
      allow_location_app: false,
      all_branches: true,
    };
  },
  created() {
    var g = this;
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        g.loading = false;
      })
      .catch(function (r) {
        alert(e("error"));
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    addNow() {
      var g = this,
        _g = this;
      _g.loading = true;
      $.post(api + "/users/import", {
        jwt: _g.user.jwt,
        arr: this.arr,
        settings_group_id: this.settings_group_id,
        all_branches: this.all_branches,
        allow_location_app: this.allow_location_app,
        branches: this.branches,
      })
        .then(function (response) {
          response = JSON.parse(response);
          if (response.status == 100) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            _g.$router.push("/users");
          } else {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(response.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
          _g.loading = false;
        })
        .catch(function () {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
    onFileChange(event) {
      var g = this,
        f = true;
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile)
        .then((rows) => {
          if (rows.length == 0 || !rows) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("error-file-forma"),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
            g.arr = [];
          } else {
            rows.forEach(function (a) {
              if (f) {
                if (!a[0] || !a[1]) {
                  g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: e("error-file-forma"),
                      icon: "TimesIcon",
                      variant: "danger",
                    },
                  });
                  f = false;
                  g.arr = [];
                } else {
                  var arr = [];
                  rows.forEach(function (a) {
                    if (a[1] != "name") {
                      arr.push({
                        name: a[0],
                        number: a[1],
                        phone: a[2],
                        notes: a[3],
                      });
                    }
                  });
                  g.arr = arr;
                }
              }
            });
          }
        })
        .catch((a) => {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: a,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          g.arr = [];
        });
    },
  },
};
</script>